import { createStore, combineReducers } from 'redux';
// import { json } from '@dagrejs/graphlib';

import * as Utils from '../utils';

// Actions - for now defined as part of reducers

// --------------------------------------------
// REDUCERS
// --------------------------------------------
// Reducer for Cloud Firestore
const initFState = {
  profiles: [],
  roles: [],
  companies: [],
  profileCount: {},
};
function firestore(state = initFState, action) {
  let collection = '';
  switch (action.type) {
    case 'BULK_UPDATE':
      collection = action.collection;
      if (collection) {
        const s = action[collection];
        if (s) {
          return { ...state, [collection]: s };
        }
        return state;
      }
      return state;
    case 'SINGULAR_UPDATE':
      collection = action.collection;
      if (collection) {
        const s = action[collection]; // Singular value
        // console.log('singular value: ', s);
        const { key } = action; // Key to use for searching
        // console.log('key to use for searching: '. key);
        if (s && key) {
          const st = { ...state };
          const cdata = [...st[collection]]; // Current collection
          // console.log('Current collection: ', collection);
          const searchFor = s[key]; // Key in singular value
          // Find index of searchFor in current collection
          const idx = cdata.findIndex((x) => x[key] === searchFor);
          if (idx < 0) { // Found an entry in current collection
            cdata.push(s);
          } else { // No entry in current collection
            cdata.splice(idx, 1, s);
          }
          return { ...state, [collection]: cdata };
        }
        return state;
      }
      return state;
    case 'RESET_ALL':
      return Utils.deepCopy(initFState);
    default:
      return state;
  }
}

// Combine all reducers
const riveraReducers = combineReducers({
  firestore,
});

// --------------------------------------------
// CREATE STORE
// --------------------------------------------
const store = createStore(riveraReducers);
export default store;

// --------------------------------------------
// DISPATCH - Dispatch actions based on events
// --------------------------------------------
let unsubscribe = []; // To be called on log-out
const fs = window.firestore;
async function setupListeners() {
  let ref = null;
  let retries = 120;
  for (;retries > 0; retries -= 1) {
    console.log('retries: ', retries);
    if (!global.role) {
      await Utils.sleep(1000);
    } else {
      break;
    }
  }

  const { orgId } = global.role;
  ref = fs.collection('roleMap').where('orgId', '==', global.role.orgId);
  unsubscribe.push(ref.onSnapshot(onRoleMapUpdate));

  ref = fs.collection('data').doc(orgId).collection('profiles');
  unsubscribe.push(ref.onSnapshot(onProfilesUpdate));

  ref = fs.collection('data').doc(orgId).collection('metaData');
  unsubscribe.push(ref.onSnapshot(onMetadataUpdate));
}

function onProfilesUpdate(querySnapshot) {
  const s = [];
  const c = {};
  querySnapshot.forEach((doc) => {
    const item = doc.data();
    if (global.role
        && (global.role.appUid === item.ownUid || global.role.isManager)) {
      s.push(item);
      c[item.state] = c[item.state] ? c[item.state] + 1 : 1;
      c.all = c.all ? c.all + 1 : 1;
    }
  });

  store.dispatch({ type: 'BULK_UPDATE', collection: 'profiles', profiles: s });
  store.dispatch({ type: 'BULK_UPDATE', collection: 'profileCount', profileCount: c });
}

function onRoleMapUpdate(querySnapshot) {
  const s = [];
  querySnapshot.forEach((doc) => {
    const item = doc.data();
    s.push(item);
  });

  store.dispatch({ type: 'BULK_UPDATE', collection: 'roles', roles: s });
}

function onMetadataUpdate(querySnapshot) {
  const s = [];
  querySnapshot.forEach((doc) => {
    const item = doc.data();
    s.push(item);
  });

  store.dispatch({ type: 'BULK_UPDATE', collection: 'metaData', metaData: s });
}

// Set up globals
const getStore = () => store;

const initStore = () => setupListeners();
const destroyStore = () => {
  unsubscribe.map((f) => f());
  store.dispatch({ type: 'RESET_ALL' });
};

global.Store = {
  get: getStore,
  init: setupListeners,
  destroy: destroyStore,
};
